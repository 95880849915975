import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useToken from '~/hooks/useToken'

export default function Index() {
  const token = useToken()
  const router = useRouter()

  useEffect(() => {
    router.push('/join')
  }, [])

  return <></>
}
